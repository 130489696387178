import React, {useEffect, useState} from 'react';
import TableCellInteractive from "../table-cell/table-cell-interactive";
import css from "./month.module.css";
import {workCodes, getWorkColor} from "../table-cell/occupation-codes";
import OccupationButtons from "../occupation-buttons/occupation-buttons";
import {serializeRequest, updateSelectedInfo} from "../table/SerializeData";
import ScheduleService from "../../API/ScheduleService";

const MonthInfo = ({dates, refreshEmployees}) => {
    const monthDays = dates.map((monthData) => monthData.workDaysData);

    /**
     * Снимаем выделение и скрываем кнопки
     */
    const toggleSelection = () => {
        setShowButtons(false);

        selectedCells.forEach(cell => {
            const occupationCode = cell.dataset.name === '' ? 'EMP' : cell.dataset.name.toUpperCase();
            const occupationInfo = workCodes[occupationCode];
            cell.style.backgroundColor = occupationInfo.color;
        });
    };

    /**
     * Отправляем изменения на бэк
     */
    const [selectedElements, setSelectedElements] = useState([]);
    const toggleWriteChanges = (chooseOccupation) => {
        // Находим все, что навыделяли
        const elements = document.querySelectorAll('[style="background-color: rgb(141, 200, 252);"]');
        const selectedElements = Array.from(elements);

        // Записываем в массив
        setSelectedElements(selectedElements);

        // Приписываем новую занятость
        const newOccupationForSelectedCells = updateSelectedInfo(selectedElements, chooseOccupation);

        // Сериализуем данные перед бэком
        const newOccupation = serializeRequest(newOccupationForSelectedCells);

        return handleWriteChanges(newOccupation);
    }

    /**
     * Альтернатива Selectable, выделяем ячейки и снимаем выделения
     */
    const [selectedCells, setSelectedCells] = useState([]);
    const [showButtons, setShowButtons] = useState(false);
    const handleCellClick = (event) => {
        const clickedCell = event.target;

        if (clickedCell.style.backgroundColor !== 'rgb(141, 200, 252)') {
            setSelectedCells(prevSelectedCells => [...prevSelectedCells, clickedCell]);

            clickedCell.style.backgroundColor = '#8dc8fc';
        } else {
            setSelectedCells(prevSelectedCells => prevSelectedCells.filter(cell => cell !== clickedCell));

            const occupationCode = clickedCell.dataset.name.toUpperCase();
            const occupationInfo = workCodes[occupationCode];
            clickedCell.style.backgroundColor = occupationInfo.color;
        }
    };

    /**
     * Отправляем измененные ячейки на бэк, перезагружаем страницу
     */
    const [error, setError] = useState("");
    const [conflictingChanges, setConflictingChanges] = useState("");
    const handleWriteChanges = async (newOccupation) => {
        try {
            // Отправляем на бэкенд
            const response = await ScheduleService.updateSchedule(newOccupation);

            if (response.data.error !== "" && response.data.message === "") {
                setError(response.data.error)
            } else if (response.data.error === "" && response.data.message !== "") {
                setConflictingChanges(response.data.message);
            } else {
                toggleSelection();
                refreshEmployees();
            }
        } catch (error) {
            // Обработка ошибки при отправке POST-запроса
            setError('Произошла ошибка при подключении к серверу');
        }
    };

    /**
     * Закрываем эррор или сообщение о конфликтных днях при изменении графика сотрудников
     */
    const handleCloseAlert = () => {
        window.location.reload();
    };

    /**
     * Нажимаем Esc для снятия выделений и скрытия кнопок
     */
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === 27) {
                toggleSelection();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [toggleSelection]);

    const [canEdit, setCanEdit] = useState(false); // Добавьте состояние для прав редактирования


    useEffect(() => {
        const webApp = window.Telegram.WebApp;
        const initData = webApp.initData

        // Разбиваем строку запроса на пары ключ-значение
        const params = new URLSearchParams(initData);

        // Получаем значение параметра 'user' и декодируем его из URL-кодирования
        const userJson = decodeURIComponent(params.get('user'));

        // Преобразуем JSON-строку в объект
        const userObject = JSON.parse(userJson);

        if (userObject !== undefined && userObject != null) {
            // Извлекаем ID пользователя
            const userId = userObject.id;

            console.log(initData)

            const fetchEditPermission = async () => {
                const permission = await ScheduleService.getEditPermission(userId);
                setCanEdit(permission);
            };
            fetchEditPermission();
        } else {
            setCanEdit(true)
        }
    }, []);

    /**
     * Отслеживаем текущее состояние списка с выделенными кнопками
     */
    useEffect(() => {
        if (selectedCells.length > 0 && canEdit) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }, [selectedCells]);

    return (
        <div className="schedule_data">
            {monthDays.map((dateRow, rowIndex) => <div
                className={css.schedule_row}
                key={rowIndex}
                style={{backgroundColor: rowIndex % 2 === 0 ? 'white' : 'lightgrey'}}
            >
                {dateRow.map((workCode, cellIndex) => {
                    const combinedValue = dates[rowIndex].article + "-" + workCode.date;

                    return (
                        <TableCellInteractive
                            key={cellIndex}
                            url={combinedValue}
                            item={workCode.dateIsWork}
                            classes={getWorkColor(workCode.dateIsWork) + " table__cell_special"}
                            onClick={handleCellClick}
                        />
                    );
                })}
            </div>)}
            {showButtons && <OccupationButtons
                toggleSelection={toggleSelection}
                toggleWriteChanges={toggleWriteChanges}
            />}
            {error && (<div className={css.error__modal}>
                <div className={css.error__content}>
                    <h2>Ошибка</h2>
                    <p>{error}</p>
                    <button onClick={handleCloseAlert}>Закрыть</button>
                </div>
            </div>)}
            {conflictingChanges && (<div className={css.error__modal}>
                <div className={css.error__content}>
                    <h2>Предупреждение</h2>
                    <div
                        className={css.conflicting__changes__content}
                        dangerouslySetInnerHTML={{__html: conflictingChanges}}
                    />
                    <button onClick={handleCloseAlert}>Закрыть</button>
                </div>
            </div>)}
        </div>
    );
};

export default MonthInfo;