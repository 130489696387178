import React from "react";

const TableCellCommon = ({url, item, classes, dateTime }) => {
    return (
        <div
            dateTime={dateTime}
            data-id={url}
            data-name={item}
            className={classes + " table__cell"}
        >
            {item}
        </div>
    )
}

export default TableCellCommon;