// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule {
    font-family: 'Roboto', sans-serif !important;
}

.working-day {
    min-width: 100%;
    text-align: center;
}

.fc-view-harness {
    /*min-height: --tg-viewport-stable-height;*/
    /*min-height: fit-content;*/
    /*overflow: hidden;*/
    /*height: 100%;*/
}

/**
 * Легенда для календаря
 */
.legendContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    flex-wrap: wrap;
    width: 100vw;
}

.legendExample {
    display: inline-block;
    width: 35vw;
    height: 10%;
    margin: 5px 5px 5px 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
    line-height: 20px;
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/schedule.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;AAChD;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,2CAA2C;IAC3C,2BAA2B;IAC3B,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;;EAEE;AACF;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".schedule {\n    font-family: 'Roboto', sans-serif !important;\n}\n\n.working-day {\n    min-width: 100%;\n    text-align: center;\n}\n\n.fc-view-harness {\n    /*min-height: --tg-viewport-stable-height;*/\n    /*min-height: fit-content;*/\n    /*overflow: hidden;*/\n    /*height: 100%;*/\n}\n\n/**\n * Легенда для календаря\n */\n.legendContainer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 5px;\n    flex-wrap: wrap;\n    width: 100vw;\n}\n\n.legendExample {\n    display: inline-block;\n    width: 35vw;\n    height: 10%;\n    margin: 5px 5px 5px 5px;\n    padding: 5px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    text-align: center;\n    line-height: 20px;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
