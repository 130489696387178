import React from 'react';
import css from './mechanic.module.css';
import {Link} from "react-router-dom";

const MechanicColumn = ({employees}) => {

    function prepareName(name) {
        let fio = name.split(" ");

        if (fio.length > 1) {
            if (fio.length > 2) {
                return fio[0] + " " + fio[1].charAt(0).toUpperCase() + ". " + fio[2].charAt(0).toUpperCase() + "."
            } else {
                return fio[0] + " " + fio[1].charAt(0).toUpperCase()
            }
        } else {
            return fio[0]
        }
    }

    return (
        <div className={css.mechanic__column}>
            <div className={css.weekday_month_year}>
                <div className="table__cell">Месяц, год</div>
                <div className={css.day_cell}>День</div>
            </div>
            <div style={{backgroundColor: "white"}}>
                {employees.map((employee, index) => (
                    <div key={index} style={{backgroundColor: index % 2 === 0 ? 'white' : 'lightgrey'}}>
                        <Link
                            className="no-link"
                            to={`/schedule/${employee.url}`}
                        >
                            <div className={css.mechanic_cell}>
                                {prepareName(employee.name)}
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default MechanicColumn;