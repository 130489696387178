import axios from "axios";

const scheduleUrl = process.env.REACT_APP_SCHEDULE_URL;
const botId = process.env.REACT_APP_BOT_ID;

export default class ScheduleService {
    static headers = {
        headers: {
            "ngrok-skip-browser-warning": true,
        }
    }

    static async getEditPermission(userId) {
        try {
            const response = await axios.get(
                `${scheduleUrl}/${botId}/twa/role/${userId}`,
                    this.headers
            );
            return response.data; // Предполагается, что сервер возвращает true/false напрямую
        } catch (error) {
            console.error('Ошибка при получении прав редактирования', error);
            throw error; // Можно обработать ошибку по-другому, если нужно
        }
    }

    static async getAll() {
        return await axios.get(
            `${scheduleUrl}` + `/${botId}` + "/twa/schedule",
            this.headers
        )
    }

    static async getById(id) {
        return await axios.get(
            `${scheduleUrl}` + `/${botId}` + "/twa/schedule/" + id,
            this.headers
        )
    }

    static async updateSchedule(data) {
        return await axios.post(
            `${scheduleUrl}` + `/${botId}` + "/twa/schedule",
            data,
            this.headers
        )
    }
}