import {useDispatch} from 'react-redux';
import Table from '../table/Table';
import {Navigate, Route, Routes} from "react-router-dom";
import Schedule from "../pages/Schedule";
import React, {useEffect, useState} from "react";
import {setUserIdCookie, getUserIdFromCookie} from "../table/CookiesMethods";

const botName = process.env.REACT_APP_BOT_NAME;
const allowedAuthIds = process.env.REACT_APP_ALLOWED_AUTH_IDS;
const allowedAuthIdsArray = allowedAuthIds.split(',').map(id => parseInt(id));

const App = () => {
    /*const starships = useSelector(({ starships }) => starships.starships);

    useEffect(() => {
      if (!starships) {
        const getNews = async () => {
          const { data } = await axios({
            method: `get`,
            url: `http://swapi.dev/api/vehicles`
          })

          const { results } = data
          dispatch(setStarships(results));

          let cargo_capacity = [];
          let cost_in_credits = [];
          let max_atmosphering_speed = [];
          let name = [];

          for (const starship of results) {
            cargo_capacity.push({
              url: starship.url,
              value: starship.cargo_capacity
            })
            cost_in_credits.push({
              url: starship.url,
              value: starship.cost_in_credits
            })
            max_atmosphering_speed.push({
              url: starship.url,
              value: starship.max_atmosphering_speed
            })
            name.push({
              url: starship.url,
              value: starship.name
            })
          };

          dispatch(setItemsList({ listName: `cargo_capacity`, list: cargo_capacity }))
          dispatch(setItemsList({ listName: `cost_in_credits`, list: cost_in_credits }))
          dispatch(setItemsList({ listName: `max_atmosphering_speed`, list: max_atmosphering_speed }))
          dispatch(setItemsList({ listName: `name`, list: name }))
        }

        getNews();
      }
    }, [starships])

    console.log('App спросили, сколько звездолетов пришло? Ответ убил:', starships)*/

    /**
     * Пришел ли пользователь из бота
     */
    const [isFromWebApp, setIsFromWebApp] = useState(false);

    /**
     * Авторизован ли пользователь через Telegram
     */
    const [isTelegramAuthorized, setTelegramAuthorized] = useState(false);

    useEffect(() => {
        const webApp = window.Telegram.WebApp;

        const userFromTelegram = () => {
            return !(webApp.initData === undefined || webApp.initData === "");
        }

        if (userFromTelegram()) {
            webApp.ready();
            webApp.enableClosingConfirmation();
            webApp.setBackgroundColor("#FFFFFF");
            webApp.expand();

            const initData = webApp.initData

            const initDataObject = Object.fromEntries(
                initData.split("&").map(entry => entry.split("="))
            );

            const userString = initDataObject.user;

            const userObject = JSON.parse(decodeURIComponent(userString));

            setUserIdCookie(userObject.id);

            setIsFromWebApp(true);
        } else if (getUserIdFromCookie()) {
            setTelegramAuthorized(true);
        } else {
            const handleTelegramAuth = () => {
                const script = document.createElement('script');
                script.async = true;
                script.src = 'https://telegram.org/js/telegram-widget.js?22';
                script.setAttribute('data-telegram-login', botName);
                script.setAttribute('data-size', 'large');
                script.setAttribute('data-onauth', 'onTelegramAuth(user)');
                script.setAttribute('data-request-access', 'write');
                document.body.appendChild(script);

                window.onTelegramAuth = (user) => {
                    if (allowedAuthIdsArray.includes(user.id)) {
                        const elements = document.querySelectorAll('[id^="telegram-login-"]');
                        elements.forEach(element => {
                            element.remove();
                        });

                        alert('Авторизован как ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')');

                        setUserIdCookie(user.id);
                        setTelegramAuthorized(true);
                    } else {
                        alert('Авторизоваться не удалось')
                    }
                };
            };

            handleTelegramAuth();
        }
    }, []);

    return (
        <div>
            {isFromWebApp || isTelegramAuthorized ? (
                <Routes>
                    <Route path="/">
                        <Route index element={<Table/>}/>
                        <Route path="schedule/:article" element={<Schedule/>}/>
                        <Route path="*" element={<Navigate to="/" replace/>}/>
                    </Route>
                </Routes>
            ) : (
                <h1>
                    Авторизация 🔐
                </h1>
            )}
        </div>
    );
}

export default App;
