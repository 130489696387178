// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Строка с занятостью
 */
.month_schedule_row__YEEX4 {
    display: flex;
    text-align: center;
    min-height: max-content;
}

/**
 * Ошибка при получении сотрудников или их занятости
 */
.month_error__modal__08PE2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.month_error__content__\\+vMvj {
    background-color: #fff;
    width: 80vw;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    white-space: normal;
}

.month_error__content__\\+vMvj h2 {
    margin-bottom: 10px;
}

.month_error__content__\\+vMvj p {
    margin-bottom: 20px;
}

.month_error__content__\\+vMvj button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1em;
}

/**
 * Запись занятости прошла успешно, но есть конфликтные дни
 */
.month_conflicting__changes__content__dPL5V {
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/month-info/month.module.css"],"names":[],"mappings":"AAAA;;EAEE;AACF;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;;EAEE;AACF;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;;EAEE;AACF;IACI,gBAAgB;AACpB","sourcesContent":["/**\n * Строка с занятостью\n */\n.schedule_row {\n    display: flex;\n    text-align: center;\n    min-height: max-content;\n}\n\n/**\n * Ошибка при получении сотрудников или их занятости\n */\n.error__modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n}\n\n.error__content {\n    background-color: #fff;\n    width: 80vw;\n    padding: 20px;\n    border-radius: 8px;\n    text-align: center;\n    white-space: normal;\n}\n\n.error__content h2 {\n    margin-bottom: 10px;\n}\n\n.error__content p {\n    margin-bottom: 20px;\n}\n\n.error__content button {\n    background-color: #007bff;\n    color: #fff;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    margin-top: 1em;\n}\n\n/**\n * Запись занятости прошла успешно, но есть конфликтные дни\n */\n.conflicting__changes__content {\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schedule_row": `month_schedule_row__YEEX4`,
	"error__modal": `month_error__modal__08PE2`,
	"error__content": `month_error__content__+vMvj`,
	"conflicting__changes__content": `month_conflicting__changes__content__dPL5V`
};
export default ___CSS_LOADER_EXPORT___;
