import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {getWorkColor, workCodes} from "../table-cell/occupation-codes";
import ruLocale from "@fullcalendar/core/locales/ru";
import ScheduleService from "../../API/ScheduleService";
import {useFetching} from "../../hooks/useFetching";
import {Loader} from "../UI/loader/Loader";
import css from "./schedule.css";

const Schedule = () => {

    const navigate = useNavigate();

    let {article} = useParams();

    const backButton = window.Telegram.WebApp.BackButton;

    const [employee, setEmployee] = useState('');

    const [fetchEmployee, isLoading, employeeError] = useFetching(async () => {
        const response = await ScheduleService.getById(article);
        setEmployee(response.data);
    })

    useEffect(() => {
        fetchEmployee();
    }, []);

    backButton.show();
    backButton.onClick(() => onClickBackButton())

    // const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;

    const scheduleDays = (employee) => {
        const objectsList = [];

        for (const key in employee.work_days) {
            if (employee.work_days.hasOwnProperty(key)) {
                const datesArray = employee.work_days[key];
                for (let i = 0; i < datesArray.length; i++) {
                    const id = key + datesArray[i];
                    objectsList.push(
                        {
                            id: id,
                            title: key,
                            // start: new Date(datesArray[i].replace(pattern, '$3-$2-$1')),
                            start: new Date(datesArray[i]),
                        }
                    );
                }
            }
        }

        return objectsList;
    }

    function renderEventContent(eventInfo) {
        const workCode = eventInfo.event.title.toUpperCase() === '' ? 'EMP' : eventInfo.event.title.toUpperCase();
        const workColor = getWorkColor(workCode);

        return (
            <div class={workColor + " working-day"}>
                {workCodes[workCode].letter}
            </div>
        );
    }

    const onClickBackButton = () => {
        backButton.hide();
        navigate('/schedule');
    }

    const colorLegend = (
        <div className="legendContainer">
            {Object.values(workCodes).map(workCode => (
                <div
                    key={workCode.code}
                    className={`legendExample ${getWorkColor(workCode.code)}`}
                >
                    <div>
                        <div>{workCode.name}</div>
                    </div>
                </div>
            ))}
        </div>
    );


    return (
        <div className="schedule">
            <div style={{textAlign: 'center'}}>
                <h1 style={{ fontSize: '1.5em' }}>
                    График сотрудника {employee.name}
                </h1>
                <div className="legendContainer">
                    {colorLegend}
                </div>
                {employeeError
                    ? <h1>Ошибка при получении графика работы {employeeError}!</h1>
                    : isLoading
                        ? <div style={{display: 'flex', justifyContent: 'center', marginTop: 50}}><Loader/></div>
                        : <FullCalendar
                            locale={ruLocale}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            editable={false}
                            selectable={false}
                            selectMirror={false}
                            dayMaxEvents={false}
                            events={true}
                            height={["90vh"]}
                            initialEvents={scheduleDays(employee)}
                            eventContent={renderEventContent}
                            headerToolbar={{
                                start: '',
                                center: 'title',
                                end: ''
                            }}
                            footerToolbar={{
                                start: 'prev',
                                center: 'today',
                                end: 'next'
                            }}
                        />
                }
            </div>
        </div>
    );
};

export default Schedule;