/**
 * Кладет User ID пользователя Telegram в куки
 */
export function setUserIdCookie(userId) {
    document.cookie = `userId=${userId}`;
}

/**
 * Достает User ID пользователя Telegram из куки
 */
export function getUserIdFromCookie() {
    const cookies = document.cookie.split(';');

    for (let cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === 'userId') {
            return value;
        }
    }

    return null;
}