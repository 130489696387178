import React, {useCallback, useEffect, useState} from 'react';
import DateInfo from "../date-info/date-info";
import MechanicColumn from "../mechanic-data/mechanic-column";
import css from './Table.module.css';
import {Loader} from "../UI/loader/Loader";
import ScheduleService from "../../API/ScheduleService";
import {useFetching} from "../../hooks/useFetching";

const Table = () => {
    /**
     * useEffect для получения сотрудников с бэка
     */
    const [employees, setEmployees] = useState([]);
    const [fetchEmployees, isLoading, employeesError] = useFetching(async () => {
        const response = await ScheduleService.getAll()
        setEmployees(response.data);
    });

    useEffect(() => {
        fetchEmployees().then(() => {
        });
    }, []);

    const refreshEmployees = async () => {
        try {
            await fetchEmployees();

            const response = await ScheduleService.getAll();
            setEmployees(response.data);
        } catch (error) {
            console.error("Ошибка при обновлении сотрудников:", error);
        }
    };

    return (
        <div>
            {employeesError && <h1>Произошла ошибка {employeesError}!</h1>}
            {isLoading ? <div style={{display: 'flex', justifyContent: 'center', marginTop: 50}}><Loader/></div> :
                <div className={css.table}>
                    <MechanicColumn employees={employees}/>
                    <DateInfo employees={employees} refreshEmployees={refreshEmployees}/>
                </div>}
        </div>
    );
};

export default Table;