import {getUserIdFromCookie} from "./CookiesMethods";

/**
 * Запись новой занятости
 */
export function updateSelectedInfo(selectedCells, chooseOccupation) {
    return selectedCells.map(cell => {
        const articleDate = cell.dataset.id;
        const occupation = chooseOccupation !== '' ? chooseOccupation : cell.dataset.name;

        return {
            articleDate,
            occupation
        };
    });
}

/**
 * Сериализация всего, что было выделено, перед отправкой на бэк
 *
 * Здесь id подразумевает ID пользователя Telegram
 */
export function serializeRequest(data) {
    const id = getUserIdFromCookie();
    const occupation = data[0].occupation;

    const result = {
        id,
        occupation,
        busy: []
    };

    const dateGroups = {};

    data.forEach(item => {
        const [prefix, datePart] = item.articleDate.split('-');
        const [year, month, day] = datePart.split('/');

        const dateObject = new Date(`${year}-${month}-${day}`);
        const daysSinceEpoch = Math.floor(dateObject.getTime() / (1000 * 60 * 60 * 24));

        if (!dateGroups[daysSinceEpoch]) {
            dateGroups[daysSinceEpoch] = [];
        }

        dateGroups[daysSinceEpoch].push(prefix);
    });

    for (const date in dateGroups) {
        result.busy.push({
            date: Number(date),
            mechanics: dateGroups[date]
        });
    }

    return result;
}