import React, {useState} from 'react';
import {getWorkColor, workCodes} from "../table-cell/occupation-codes";
import css from "../occupation-buttons/occupation.module.css";

const OccupationButtons = ({toggleSelection, toggleWriteChanges}) => {
    /**
     * Выбираем занятость
     */
    const [chooseOccupation, setChooseOccupation] = useState(null);
    const handleOccupationClick = (letter) => {
        setChooseOccupation(letter);
    };

    /**
     * Снимаем выделение и скрываем кнопки
     */
    const handleClearSelection = () => {
        toggleSelection();
    };

    /**
     * Отправляем изменения на бэк
     */
    const handleWriteChanges = () => {
        toggleWriteChanges(chooseOccupation);
    };

    return (
        <div style={{position: "fixed", bottom: 0, left: 0, zIndex: 4, width: "100%"}}>
            <div className={css.buttonContainer}>
                {Object.values(workCodes).map(({code, letter}) => (
                    <button
                        key={code}
                        className={getWorkColor(code) === 'work_code__emp' ? getWorkColor('emp--button') : getWorkColor(code)}
                        onClick={() => handleOccupationClick(code)}
                    >
                        {letter !== '' ? letter : '\u00A0'}
                    </button>
                ))}
                {chooseOccupation && (<div className={css.additionalButtons}>
                    <button
                        onClick={handleClearSelection}
                    >❌
                    </button>
                    <button
                        onClick={handleWriteChanges}
                    >✅
                    </button>
                </div>)}
            </div>
        </div>);
};

export default OccupationButtons;