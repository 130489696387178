// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Заголовок месяца
 */
.date_table__month__column__header__s0KKI {
    font-weight: bold;
    background: #ecd2b5;
    position: sticky;
    top: 0;
    z-index: 4;
}

/**
 * Месяц, год
 */
.date_year_title__TwMLz {
    width: 90vw;
    position: sticky;
    left: 10vw;
}

/**
 * День недели, число
 */
.date_week_title__vFBB4 {
    display: flex;
}

.date_table__weekday_cell__jR6Rh {
    border: 1px solid rgba(0, 0, 0, 0.44);
    padding: 10px 5px;
    width: 30px;
    height: 2em !important;
    flex: 1 1;
    text-align: center;
    white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/components/date-info/date.module.css"],"names":[],"mappings":"AAAA;;EAEE;AACF;IACI,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,MAAM;IACN,UAAU;AACd;;AAEA;;EAEE;AACF;IACI,WAAW;IACX,gBAAgB;IAChB,UAAU;AACd;;AAEA;;EAEE;AACF;IACI,aAAa;AACjB;;AAEA;IACI,qCAAqC;IACrC,iBAAiB;IACjB,WAAW;IACX,sBAAsB;IACtB,SAAO;IACP,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":["/**\n * Заголовок месяца\n */\n.table__month__column__header {\n    font-weight: bold;\n    background: #ecd2b5;\n    position: sticky;\n    top: 0;\n    z-index: 4;\n}\n\n/**\n * Месяц, год\n */\n.year_title {\n    width: 90vw;\n    position: sticky;\n    left: 10vw;\n}\n\n/**\n * День недели, число\n */\n.week_title {\n    display: flex;\n}\n\n.table__weekday_cell {\n    border: 1px solid rgba(0, 0, 0, 0.44);\n    padding: 10px 5px;\n    width: 30px;\n    height: 2em !important;\n    flex: 1;\n    text-align: center;\n    white-space: pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table__month__column__header": `date_table__month__column__header__s0KKI`,
	"year_title": `date_year_title__TwMLz`,
	"week_title": `date_week_title__vFBB4`,
	"table__weekday_cell": `date_table__weekday_cell__jR6Rh`
};
export default ___CSS_LOADER_EXPORT___;
