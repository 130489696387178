// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * Документ
 */
body {
  margin: 0;
  padding: 0;
  overflow: auto;
  background-color: #fff;
}

/**
 * Обычная ячейка
 */
.table__cell {
  border: 1px solid rgba(0, 0, 0, 0.65);
  padding: 10px 5px;
  height: 1em;
  flex: 1 1;
  text-align: center;
}

/**
 * Цвета занятости
 */
.work_code__kar {
  font-weight: bold;
  background: #6aa84f;
  color: white;
}

.work_code__tru {
  font-weight: bold;
  background: transparent;
  color: white;
}

.work_code__vac {
  font-weight: bold;
  background: #ea9999;
  color: white;
}

.work_code__tou {
  font-weight: bold;
  background: #8e7cc3;
  color: white;
}

.work_code__tra {
  font-weight: bold;
  background: #ff00ff;
  color: white;
}

.work_code__out {
  font-weight: bold;
  background: transparent;
  color: white;
}

.work_code__emp {
  background: transparent;
}

.work_code__emp--button {
  background: white;
}

/**
 * Календарь
 */
.no-link {
  color: black;
  text-decoration: none;
}
.no-link:focus, .no-link:hover, .no-link:visited, .no-link:link, .no-link:active {
  text-decoration: none;
}

.fc {
  /* the calendar root */
  height: 100%;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/styles.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;EAAA;AAGA;EACE,SAAA;EACA,UAAA;EACA,cAAA;EACA,sBAAA;AAEF;;AACA;;EAAA;AAGA;EACE,qCAAA;EACA,iBAAA;EACA,WAAA;EACA,SAAA;EACA,kBAAA;AAEF;;AACA;;EAAA;AAGA;EACE,iBAAA;EACA,mBAAA;EACA,YAAA;AAEF;;AACA;EACE,iBAAA;EACA,uBAAA;EACA,YAAA;AAEF;;AACA;EACE,iBAAA;EACA,mBAAA;EACA,YAAA;AAEF;;AACA;EACE,iBAAA;EACA,mBAAA;EACA,YAAA;AAEF;;AACA;EACE,iBAAA;EACA,mBAAA;EACA,YAAA;AAEF;;AACA;EACE,iBAAA;EACA,uBAAA;EACA,YAAA;AAEF;;AACA;EACE,uBAAA;AAEF;;AACA;EACE,iBAAA;AAEF;;AACA;;EAAA;AAGA;EACE,YAAA;EACA,qBAAA;AAEF;AAAE;EACE,qBAAA;AAEJ;;AAEA;EACE,sBAAA;EACA,YAAA;EACA,cAAA;AACF","sourcesContent":["/**\n * Документ\n */\nbody {\n  margin: 0;\n  padding: 0;\n  overflow: auto;\n  background-color: #fff;\n}\n\n/**\n * Обычная ячейка\n */\n.table__cell {\n  border: 1px solid rgba(0, 0, 0, 0.65);\n  padding: 10px 5px;\n  height: 1em;\n  flex: 1;\n  text-align: center;\n}\n\n/**\n * Цвета занятости\n */\n.work_code__kar {\n  font-weight: bold;\n  background: #6aa84f;\n  color: white;\n}\n\n.work_code__tru {\n  font-weight: bold;\n  background: transparent;\n  color: white;\n}\n\n.work_code__vac {\n  font-weight: bold;\n  background: #ea9999;\n  color: white;\n}\n\n.work_code__tou {\n  font-weight: bold;\n  background: #8e7cc3;\n  color: white;\n}\n\n.work_code__tra {\n  font-weight: bold;\n  background: #ff00ff;\n  color: white;\n}\n\n.work_code__out {\n  font-weight: bold;\n  background: transparent;\n  color: white;\n}\n\n.work_code__emp {\n  background: transparent;\n}\n\n.work_code__emp--button {\n  background: white;\n}\n\n/**\n * Календарь\n */\n.no-link {\n  color: black;\n  text-decoration: none;\n\n  &:focus, &:hover, &:visited, &:link, &:active {\n    text-decoration: none;\n  }\n}\n\n.fc {\n  /* the calendar root */\n  height: 100%;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
