// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader__az6VC {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px dashed darkcyan;
    animation: Loader_rotate__chv3j 1s infinite linear;
}

@keyframes Loader_rotate__chv3j {
    from {
        transform: rotate(0deg) scale(1);
    }
    to {
        transform: rotate(360deg) scale(1.2);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/loader/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,2BAA2B;IAC3B,kDAAoC;AACxC;;AAEA;IACI;QACI,gCAAgC;IACpC;IACA;QACI,oCAAoC;IACxC;AACJ","sourcesContent":[".loader {\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    border: 1px dashed darkcyan;\n    animation: rotate 1s infinite linear;\n}\n\n@keyframes rotate {\n    from {\n        transform: rotate(0deg) scale(1);\n    }\n    to {\n        transform: rotate(360deg) scale(1.2);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__az6VC`,
	"rotate": `Loader_rotate__chv3j`
};
export default ___CSS_LOADER_EXPORT___;
