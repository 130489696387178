import React from 'react';
import {workCodes} from './occupation-codes'

const TableCellInteractive = ({url, item, classes, onClick}) => {

    return (
        <div
            data-id={url}
            data-name={item}
            className={classes + " table__cell"}
            onClick={onClick}
        >
            <div
                className="cell-content"
                style={{ pointerEvents: 'none' }}
            >
                {workCodes[item.toUpperCase()] && workCodes[item.toUpperCase()].letter}
            </div>
        </div>
    )
}

export default TableCellInteractive;