// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mechanic_mechanic__column__naa7A {
    position: sticky;
    width: 35vw;
    left: 0;
    z-index: 10;
}

.mechanic_weekday_month_year__m\\+s76 {
    font-weight: bold;
    background: #ecd2b5;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 5;
}

.mechanic_day_cell__-N2TP {
    border: 1px solid rgba(0, 0, 0, 0.65);
    padding: 10px 5px;
    height: 2em;
    text-align: center;
    white-space: pre-wrap;
}

.mechanic_mechanic_cell__\\+7sRH {
    border: 1px solid rgba(0, 0, 0, 0.65);
    padding: 10px 5px;
    height: 1em;
    flex: 1 1;
    text-align: center;
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/mechanic-data/mechanic.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,OAAO;IACP,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,MAAM;IACN,OAAO;IACP,UAAU;AACd;;AAEA;IACI,qCAAqC;IACrC,iBAAiB;IACjB,WAAW;IACX,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,qCAAqC;IACrC,iBAAiB;IACjB,WAAW;IACX,SAAO;IACP,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".mechanic__column {\n    position: sticky;\n    width: 35vw;\n    left: 0;\n    z-index: 10;\n}\n\n.weekday_month_year {\n    font-weight: bold;\n    background: #ecd2b5;\n    position: sticky;\n    top: 0;\n    left: 0;\n    z-index: 5;\n}\n\n.day_cell {\n    border: 1px solid rgba(0, 0, 0, 0.65);\n    padding: 10px 5px;\n    height: 2em;\n    text-align: center;\n    white-space: pre-wrap;\n}\n\n.mechanic_cell {\n    border: 1px solid rgba(0, 0, 0, 0.65);\n    padding: 10px 5px;\n    height: 1em;\n    flex: 1;\n    text-align: center;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mechanic__column": `mechanic_mechanic__column__naa7A`,
	"weekday_month_year": `mechanic_weekday_month_year__m+s76`,
	"day_cell": `mechanic_day_cell__-N2TP`,
	"mechanic_cell": `mechanic_mechanic_cell__+7sRH`
};
export default ___CSS_LOADER_EXPORT___;
