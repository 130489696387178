// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Таблица
 */
.Table_table__iHjPY {
    font-size: 0.8em;
    font-family: 'Roboto', sans-serif;
    overflow-x: auto;
    white-space: nowrap;
    width: 100vw;
    height: 79vh;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/table/Table.module.css"],"names":[],"mappings":"AAAA;;EAEE;AACF;IACI,gBAAgB;IAChB,iCAAiC;IACjC,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,aAAa;AACjB","sourcesContent":["/**\n * Таблица\n */\n.table {\n    font-size: 0.8em;\n    font-family: 'Roboto', sans-serif;\n    overflow-x: auto;\n    white-space: nowrap;\n    width: 100vw;\n    height: 79vh;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Table_table__iHjPY`
};
export default ___CSS_LOADER_EXPORT___;
