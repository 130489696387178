// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Кнопки с занятостью
 */
.occupation_buttonContainer__69jsy {
    font-family: 'Roboto', sans-serif;
    height: 20vh;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #ecd2b5;
    padding: 1em;
    z-index: 10;
    display: flex;
    box-sizing: border-box;
}

.occupation_buttonContainer__69jsy button {
    border: 1px solid rgba(0, 0, 0, 0.65);
    flex: 1 1;
    font-weight: bold;
    font-size: 2vh;
    height: 100%;
    cursor: pointer;
}

.occupation_buttonContainer__69jsy button:hover {
    background-color: #3284ba;
}

.occupation_additionalButtons__pAPXO {
    display: flex;
    height: 100%;
    width: 30%;
}

.occupation_additionalButtons__pAPXO button {
    border: 1px solid rgba(0, 0, 0, 0.65);
    flex: 1 1;
    background-color: #868383;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/occupation-buttons/occupation.module.css"],"names":[],"mappings":"AAAA;;EAEE;AACF;IACI,iCAAiC;IACjC,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,qCAAqC;IACrC,SAAO;IACP,iBAAiB;IACjB,cAAc;IACd,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,qCAAqC;IACrC,SAAO;IACP,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":["/**\n * Кнопки с занятостью\n */\n.buttonContainer {\n    font-family: 'Roboto', sans-serif;\n    height: 20vh;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100vw;\n    background-color: #ecd2b5;\n    padding: 1em;\n    z-index: 10;\n    display: flex;\n    box-sizing: border-box;\n}\n\n.buttonContainer button {\n    border: 1px solid rgba(0, 0, 0, 0.65);\n    flex: 1;\n    font-weight: bold;\n    font-size: 2vh;\n    height: 100%;\n    cursor: pointer;\n}\n\n.buttonContainer button:hover {\n    background-color: #3284ba;\n}\n\n.additionalButtons {\n    display: flex;\n    height: 100%;\n    width: 30%;\n}\n\n.additionalButtons button {\n    border: 1px solid rgba(0, 0, 0, 0.65);\n    flex: 1;\n    background-color: #868383;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": `occupation_buttonContainer__69jsy`,
	"additionalButtons": `occupation_additionalButtons__pAPXO`
};
export default ___CSS_LOADER_EXPORT___;
