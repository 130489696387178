import { createSlice } from '@reduxjs/toolkit';

export const employeesSlice = createSlice({
  name: 'employees',
  initialState: {
    employees: null,
    articles: [],
    names: [],
    work_days: [],
  },
  reducers: {
    setEmployees: (state, action) => {
      state.employees = action.payload;

      state.employees.forEach(employee => {
        const url = `/employees/${employee.article}`;
        employee.url = url;
      });
    },
    setItemsList: (state, action) => {
      const { listName, list } = action.payload;
      state[listName] = list;
    },
    updateItem: (state, action) => {
      const { value, listName, url } = action.payload;

      const updatingItem = state[listName].find(listItem => listItem.url === url);
      const updatingIndex = state[listName].findIndex(listItem => listItem.url === url);

      if (updatingIndex < 0) {
        throw new Error(`no such index`);
      }

      updatingItem.value = value;

      state[listName] = [
        ...state[listName].slice(0, updatingIndex),
        updatingItem,
        ...state[listName].slice(updatingIndex + 1),
      ];
    },
  },
});

export const { setEmployees, setItemsList, updateItem } = employeesSlice.actions;

export default employeesSlice.reducer;