export const workCodes = {
    KAR: {code: 'kar', letter: 'К', name: 'Карамышевская', color: '#6aa84f'},
    // Убрана работа на Трубной!
    // TRU: {code: 'tru', letter: '❗️', name: 'Трубная (❗️)', color: 'transparent'},
    VAC: {code: 'vac', letter: 'О', name: 'Отпуск', color: '#ea9999'},
    TOU: {code: 'tou', letter: 'Т', name: 'В туре', color: '#8e7cc3'},
    TRA: {code: 'tra', letter: 'В', name: 'Выезд', color: '#ff00ff'},
    // Убран выезд!
    // OUT: {code: 'out', letter: '❗️', name: 'Выезд (❗️)', color: 'transparent'},
    EMP: {code: 'emp', letter: '', name: 'Не работает', color: 'transparent'}
};

export const getWorkColor = (occupationCode) => {
    const workCode = workCodes[occupationCode.toUpperCase()];

    if (workCode) {
        return "work_code__" + workCode.code;
    } else {
        return "";
    }
}