import React, {useEffect} from 'react';
import MonthInfo from "../month-info/month-info";
import TableCellCommon from "../table-cell/table-cell-common";
import css from "./date.module.css"

const DateInfo = ({employees, refreshEmployees}) => {
    /**
     * Функция для получения всех месяцев из данных о работе сотрудников
     */
    function getAllMonths(employees) {
        return employees
            .flatMap(employee =>
                Object
                    .values(employee.work_days)
                    .flatMap(days =>
                        days.map(day => new Date(day))))
            .sort((a, b) => a - b);
    }

    /**
     * Форматирование месяца с годом на английском
     */
    function formatMonthYear(date) {
        return date.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric'
        });
    }

    /**
     * Форматирование месяца с годом на русском
     */
    function formatMonthYearInCell(date) {
        return date.toLocaleDateString('ru-RU', {
            month: 'long',
            year: 'numeric'
        })
    }

    /**
     * Форматирование дня недели с числом месяца
     */
    function formatWeekDay(date) {
        return date.toLocaleDateString('ru-RU', {
            weekday: 'short',
            day: '2-digit'
        });
    }

    /**
     * Функция для добавления недостающих месяцев
     */
    function addMonths(date, months) {
        const newDate = new Date(date);
        const day = newDate.getDate();

        newDate.setMonth(newDate.getMonth() + months);
        
        if (newDate.getDate() < day) {
            newDate.setDate(0);
        }

        return newDate;
    }

    /**
     * Функция для формирования массива всех месяцев с учетом недостающих
     */
    function getCompleteMonthArray(allMonths) {
        const monthsQuantity = [];

        let firstMonth, lastMonth;

        if (allMonths.length === 0) {
            const todayMonth = new Date();

            monthsQuantity.push(formatMonthYear(addMonths(todayMonth, -1)))
            monthsQuantity.push(formatMonthYear(todayMonth));

            lastMonth = addMonths(todayMonth, 0);
        } else {
            firstMonth = new Date(allMonths[0]);
            lastMonth = new Date(allMonths[allMonths.length - 1]);

            let currentDate = new Date(firstMonth);

            while (currentDate <= lastMonth) {
                monthsQuantity.push(formatMonthYear(currentDate));
                currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
            }

            firstMonth.setMonth(firstMonth.getMonth() - 1);
        }

        const lastMonthPlusOne = addMonths(lastMonth, 1);
        const lastMonthPlusTwo = addMonths(lastMonthPlusOne, 1);
        const lastMonthPlusThree = addMonths(lastMonthPlusTwo, 1);

        return [
            firstMonth && formatMonthYear(firstMonth),
            ...monthsQuantity,
            formatMonthYear(lastMonthPlusOne),
            formatMonthYear(lastMonthPlusTwo),
            formatMonthYear(lastMonthPlusThree)
        ].filter(Boolean);
    }

    /**
     * Функция для формирования объекта с информацией о месяцах и датах
     */
    function getMonthsInfo(updatedMonths) {
        return updatedMonths.map(monthYear => {
            const [monthName, year] = monthYear.split(' ');
            const month = new Date(Date.parse(`${monthName} 1, ${year}`)).getMonth() + 1;
            const daysCount = new Date(year, month, 0).getDate();
            const monthDates = [];

            for (let dayIndex = 1; dayIndex <= daysCount; dayIndex++) {
                monthDates.push(new Date(year, month - 1, Number(dayIndex)))
            }

            return {
                monthYear: monthYear,
                monthDates: monthDates,
            };
        });
    }

    /**
     * Основная функция для получения расписания
     */
    function getScheduleForMonths(monthsInfo, employees) {
        return monthsInfo.map(monthInfo => {
            return getSchedule(monthInfo.monthDates, employees);
        });
    }

    /**
     * Функция для получения расписания на конкретный месяц
     */
    function getSchedule(monthDates, employees) {
        let schedule = [];
        for (const index in employees) {
            const employeeData = employees[index];
            const employeeWorkingDays = [];
            for (const date of monthDates) {
                const formattedDate = date.toLocaleDateString('en-ZA');
                let dateIsWork = "emp";
                for (const occupation in employeeData.work_days) {
                    if (employeeData.work_days[occupation].includes(formattedDate)) {
                        if (dateIsWork === "emp") {
                            dateIsWork = occupation;
                        }
                    }
                }
                let dateWorkInfo = {"date": formattedDate, "dateIsWork": dateIsWork}
                employeeWorkingDays.push(dateWorkInfo);
            }
            const employeeSchedule = {
                article: employeeData.url,
                workDaysData: employeeWorkingDays
            }
            schedule.push(employeeSchedule);
        }
        return schedule;
    }

    const allMonths = getAllMonths(employees);
    const monthsQuantity = getCompleteMonthArray(allMonths);
    const monthsInfo = getMonthsInfo(monthsQuantity);
    const scheduleForMonths = getScheduleForMonths(monthsInfo, employees);

    /**
     * Фокусирует на сегодняшнем дне
     */
    useEffect(() => {
        const today = new Date();
        const formattedToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        const element = document.querySelector(`[dateTime="${formattedToday}"]`);

        if (element) {
            element.setAttribute('tabIndex', '-1');
            element.focus();
        }
    }, []);

    return (
        <div style={{display: 'flex'}}>
            {monthsInfo.map((monthInfo, index) =>
                <div className={"month_block"}
                     key={index}>
                    <div className={css.table__month__column__header}>
                        <div className={css.year_title}>
                            <TableCellCommon
                                key={index}
                                url={monthInfo.monthYear}
                                item={
                                    (() => {
                                        const [month, year] = monthInfo.monthYear.split(' ');
                                        const formattedMonthYear = `${month} 1, ${year}`;
                                        const formattedDate = new Date(formattedMonthYear);
                                        const formattedDateInCell = formatMonthYearInCell(formattedDate);

                                        return formattedDateInCell.charAt(0).toUpperCase() +
                                            formattedDateInCell.slice(1);
                                    })()
                                }
                            />
                        </div>
                        <div className={css.week_title}>
                            {monthInfo.monthDates.map((date, dateIndex) =>
                                <TableCellCommon
                                    classes={css.table__weekday_cell}
                                    key={dateIndex}
                                    url={index}
                                    item={
                                        formatWeekDay(date).charAt(0).toUpperCase() +
                                        formatWeekDay(date).slice(1)
                                    }
                                    dateTime={date}
                                />
                            )}
                        </div>
                    </div>
                    <MonthInfo
                        key={index}
                        month={monthInfo.monthYear}
                        dates={scheduleForMonths[index]}
                        refreshEmployees={refreshEmployees}
                    />
                </div>
            )}
        </div>
    );
};

export default DateInfo;